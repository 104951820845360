import { Locales } from './locales';

import en from './en.json';
import ru from './ru.json';
import es from './es.json';
import tr from './tr.json';
import uk from './uk.json';
import pt from './pt.json';

export const messages = {
  [Locales.EN]: en,
  [Locales.RU]: ru,
  [Locales.ES]: es,
  [Locales.TR]: tr,
  [Locales.UA]: uk,
  [Locales.PT]: pt,
};

export const defaultLocale = Locales.EN;
